<template>
    <div>
        <h2><router-link :to="{ name: 'saleorder' }">รายการขาย</router-link> > อัพโหลดสลิป {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.name"
                        label="ชื่อ - นามสกุล *"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByName'"
                        dense
                        ref="customerNameCombobox"                    
                        item-text="name"
                        item-value="name"
                        :filter="filterCustomerByName"
                        :lazySearch="true"
                        @change="selectCustomer"
                        :rules="[validators.required]"
                      >
                      </delay-combobox>
                    </v-col>  
                    <v-col cols="12" md="6">
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.tel"
                        label="เบอร์ *"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListByTel'"
                        dense
                        ref="customerTelCombobox"                    
                        item-text="tel"
                        item-value="tel"
                        :filter="filterCustomerByTel"
                        :lazySearch="true"
                        @change="selectCustomer"
                        :rules="[validators.required]"
                      >
                      </delay-combobox>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">                      
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.sns_name"
                        label="ชื่อในโซเชียล"                    
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/saleorder/customerListBySNSName'"
                        dense
                        ref="customerSNSNameCombobox"                    
                        item-text="name"
                        item-value="name"
                        :filter="filterCustomerBySNSName"
                        :lazySearch="true"
                        @change="selectCustomerBySNSName"                        
                      >
                      </delay-combobox>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="order.email"
                        outlined
                        label="อีเมล"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                        <v-text-field 
                            v-model="order.address"                         
                            label="ที่อยู่ *"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[validators.required]"
                        ></v-text-field>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense v-model="order.city" 
                        placeholder="แขวง/ตำบล *" 
                        name="city" 
                        type="district" 
                        @select="setAddress"                        
                        :rules="[validators.required]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.county" 
                        placeholder="เขต/อำเภอ *" 
                        name="county" 
                        type="amphoe" 
                        @select="setAddress"
                        :rules="[validators.required]"                        
                        />
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.province" 
                        placeholder="จังหวัด *" 
                        name="province" 
                        type="province" 
                        @select="setAddress"
                        :rules="[validators.required]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <VThailandAddressAutoComplete 
                        hide-details="auto" 
                        dense 
                        v-model="order.postcode" 
                        placeholder="เลขไปรษณีย์ *" 
                        name="postcode" 
                        type="zipcode" 
                        @select="setAddress"
                        :rules="[validators.required]"
                        />
                    </v-col>                    
                  </v-row>                  
                </v-container>
              </v-form>

              <v-card-actions class="justify-end">                
                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'saleorder' })"
                >
                กลับ
                </v-btn>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm(false)"
                >
                ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'
import DelayCombobox from '@/components/DelayCombobox.vue'
import ProductInput from '@/components/order/ProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { minValueValidator, numberValidator, required } from '@core/utils/validation'
import { isString } from '@vueuse/shared'
export default {
  data() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      validators: {
        required,
        numberValidator,
        minValueValidator,
      },
    }
  },
  async created() {
    const saleorder_id = this.$route.params.saleorder_id

    this.order = new Order({})

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/saleorder/' + saleorder_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
        }

        const saleorder_id = this.$route.params.saleorder_id
        const retResult = await asyncPostAsJson('/saleorder/' + saleorder_id + '/info', sendData)

        this.$router.push({ name: 'saleorder' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new Order(orderDataFromServer)

      const { products, warehouse } = orderDataFromServer

      this.warehouse = warehouse
      /*

      const order = this.order

      products.forEach(element => {
        const orderItem = new OrderItem(
          element.id,
          element.images && !!element.images[0] ? element.images[0].URI : null,
          element.name,
          element.sku,
          element.barCode,
          element.pivot.note,
          element.pivot.quantity,
          element.pivot.price,
          element.pivot.discount,
          element.pivot.discount_type,
          element.pivot.vat,
        )

        order.addOrderItem(orderItem)
      })
      */
    },
    setAddress(address) {
      this.order.city = address.district
      this.order.county = address.amphoe
      this.order.province = address.province
      this.order.postcode = address.zipcode
    },
    filterCustomerByName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerByTel(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.tel && item.tel.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerBySNSName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    selectCustomer(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.postcode = postcode
      this.order.province = province
    },
    selectCustomerBySNSName(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload.customer

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.sns_name = payload.name
      this.order.postcode = postcode
      this.order.province = province
    },
  },
  components: {
    ProductInput,
    DelayCombobox,
  },
}
</script>,